<template>
	<v-dialog 
	v-model="dialog" 
	max-width="290px"
	persistent>
		<template v-slot:activator="{ on, attrs }" >
			<v-list-item v-if="componentType==0" class="pl-2" v-bind="attrs" v-on="on">
				<v-list-item-icon class="mr-2">
					<v-icon dense>mdi-pencil</v-icon>
				</v-list-item-icon>
				<v-list-item-title>{{lviews.edit}}</v-list-item-title>
			</v-list-item>

			<v-btn v-if="componentType==1"
			class="mx-2 mb-10 orange"
			fab
			absolute
			bottom
			right
			v-bind="attrs"
			v-on="on"
			>
				<v-icon class="white--text">mdi-plus</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title class="font-weight-regular">{{title}}</v-card-title>
			<v-divider class="primary"></v-divider>
			<div class="addButtonWrapper">
				<p v-if="componentType==0" class="ml-5 mt-3 mb-1 font-weight-medium">{{getProjectName(project)}}</p>
				<v-select v-if="componentType==1"
				v-model="selectedProject"
				:items="dropdownProjects"
				:label="lviews.selectProject"
				class="ml-5 mr-2"
				></v-select>
				<!-- <v-btn
					color="primary"
					class="mr-0 mt-0"
					@click="addItem"
					>
					<v-icon>mdi-plus</v-icon>
					</v-btn> -->
					<v-icon v-if="addItemPermission==true" class="primary--text mr-3 mt-2" large @click="addItem">mdi-plus</v-icon>
				</div>

			<v-card-text>
				<div class="contentWrapper">
					<v-list dense>
						<v-list-item class="pa-0">
							<v-list-item-icon class="mr-2">
								<v-icon class="primary--text">mdi-login-variant</v-icon>
							</v-list-item-icon>
							<v-list-item-content class="mr-6">
								<v-list-item-title>{{lviews.entering}}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-icon class="mr-2">
								<v-icon class="primary--text">mdi-logout-variant</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{lviews.leaving}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item class="pa-0" v-for="pair in mutableLoginLogoutPairs" :key="pair.login.id">
							<v-list-item-content class="mx-0">
								<v-text-field
								v-model="pair.login.hour"
								type="time"
								dense
								class="ml-3 mr-0 ma-0 dateTextField"
								:disabled="pair.login.deleted || !pair.login || !pair.login.change"
								></v-text-field>
							</v-list-item-content>
							<v-icon v-if="!pair.login && addItemPermission==true" class="ml-0 mr-3" @click="addRecord(pair, 0)">mdi-plus</v-icon>
							<RemoveRecord v-if="pair.login.id && !pair.login.deleted && pair.login.delete" @confirmDelete="confirmDelete($event)" :item="pair.login" :title="getProjectName(pair.login.project)+' - '+pair.login.hour"></RemoveRecord>
							<v-icon v-if="pair.login.deleted" dense class="green--text ml-0 mr-3" @click="restoreItem(pair.login)">mdi-delete-restore</v-icon>
							<v-icon v-if="pair.login.registered_at_utc && !pair.login.id" dense class="ml-0 mr-3" @click="deleteNewItem(pair, 0)">mdi-delete</v-icon>

							<v-list-item-content>
								<v-text-field
								v-model="pair.logout.hour"
								type="time"
								dense
								class="ml-3 dateTextField"
								:disabled="pair.logout.deleted || !pair.logout ||!pair.logout.change"
								></v-text-field>
							</v-list-item-content>
							<v-icon v-if="!pair.logout && addItemPermission==true" class="ml-0 mr-3" @click="addRecord(pair, 1)">mdi-plus</v-icon>

							<RemoveRecord v-if="pair.logout.id && !pair.logout.deleted && pair.logout.delete" @confirmDelete="confirmDelete($event)" :item="pair.logout" :title="getProjectName(pair.logout.project)+' - '+pair.logout.hour"></RemoveRecord>
							<v-icon v-if="pair.logout.deleted" dense class="green--text ml-0 mr-3" @click="restoreItem(pair.logout)">mdi-delete-restore</v-icon>
							<v-icon v-if="pair.logout.registered_at_utc && !pair.logout.id" dense class="ml-0 mr-3" @click="deleteNewItem(pair, 1)">mdi-delete</v-icon>
						</v-list-item>
					</v-list>

					<v-list dense class="pa-0" v-if="mutableUndefinedRecords.length>0">
						<v-list-item class="pa-0">
							<v-list-item-content>
								<v-list-item-title>Undefined records</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-for="item in mutableUndefinedRecords" :key="item.id" class="mt-2 mb-4 px-2">
							<div style="display: flex; align-items: center;">
								<v-icon v-if="item.type==0" class="primary--text" dense>mdi-login-variant</v-icon>
								<v-icon v-if="item.type==1" class="primary--text" dense>mdi-logout-variant</v-icon>
								<v-icon v-if="item.type==2" class="red--text" dense>mdi-help-circle</v-icon>
								<div style="height: 30px;">
								<p class="mt-1 mx-2">{{item.hour}}</p>
								</div>
								<div style="height: 30px;">
								<v-select
								v-model="item.type"
								:items="dropdownType"
								item-text="name"
								item-value="value"
								:label="lviews.setAs"
								class="my-0 mx-1"
								:disabled="item.deleted"
								style="font-size: 15px;"
								dense>
								</v-select>
								</div>
								<v-icon v-if="item.deleted" dense class="green--text ml-0 mr-3" @click="restoreItem(item)">mdi-delete-restore</v-icon>
								<RemoveRecord v-else @confirmDelete="confirmDelete($event)" :item="item" :title="getProjectName(item.project)+' - '+item.hour"></RemoveRecord>
							</div>
						</v-list-item>
					</v-list>
				</div>

				<p class="error--text">{{errorMessage}}</p>

				<div class="actionButtonsWrapper">
					<v-btn
					color="grey"
					class="mr-0 mt-0"
					text
					@click="dialog=false, errorMessage=null, resetEditedData()"
					>{{lbuttons.cancel}}</v-btn>
					<v-btn
					color="primary"
					class="mr-0 mt-0"
					text
					@click="save"
					:loading="loading"
					>{{lbuttons.save}}</v-btn>
				</div>
			</v-card-text>			
		</v-card>
	</v-dialog>
</template>

<script>
//import RemoveRecord from './RemoveRecord.vue'
const RemoveRecord = () => import('./RemoveRecord.vue');
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {

	name: 'EditRecord_new',

	components: {
		RemoveRecord
	},

	data: function() {
		return {
			dialog: false,
			loading: false,
			selectedProject: "",
			errorMessage: "",
			mutableLoginLogoutPairs: [],
			mutableUndefinedRecords: [],
			dropdownType: [
				{
					name: "Entering",
					value: 0
				},
				{
					name: "Leaving",
					value: 1
				}
			]
		}
	},
    props: {
		title: String,
        loginLogoutPairs: [],
		undefinedRecords: [],
        project: Number,
		date: Date,
		worker_id: Number,
		componentType: Number,
		dropdownProjects: [],
		allWorkerProjects: [],
		addItemPermission: {
			type: Boolean,
			default: true
		}
    },
	computed: {
		lviews: { get: function () { return this.$t('views.commons.editRecordDialog'); } },
		lbuttons: { get: function () { return this.$t('commons.buttons'); } },
		lmessages: { get: function () { return this.$t('commons.messages'); } },

	},
    mounted(){
		this.dropdownType[0].name = this.lviews.entering
		this.dropdownType[1].name = this.lviews.leaving
		if(this.loginLogoutPairs){
			for(let item of this.loginLogoutPairs){
				let newItem = {
					project: item.project,
					login: item.login? Object.assign({}, item.login): "",
					logout: item.logout? Object.assign({}, item.logout): ""
				}
				this.mutableLoginLogoutPairs.push(newItem)
			}
		}
		if(this.undefinedRecords){
			for(let item of this.undefinedRecords){
				let newItem={
					id: item.id,
					hour: item.hour,
					type: item.type,
					deleted: false,
					project: item.project,
					date: item.registered_at_original.substr(0,10)
				}
				this.mutableUndefinedRecords.push(newItem)
			}
		}
		if(this.componentType==1){
			this.mutableLoginLogoutPairs.push(
				{ 
					project: this.project,
				login: "",
				logout: ""
				})
		}
    },
	methods: {
		// fixHourString(hour){
		// 	if(!hour){
		// 		return 'brak godziny'
		// 	}
		// 	let splitHour=hour.split(':')
		// 	let hours=splitHour[0]
		// 	let minutes=splitHour[1]
		// 	if(hours.length<2){
		// 		hours='0'+hours
		// 	}
		// 	if(minutes.length<2){
		// 		minutes='0'+minutes
		// 	}
		// 	return hours+":"+minutes
		// },
		getProjectName(projectId){
			if(this.allWorkerProjects){
				let project = this.allWorkerProjects.find(item => item.project==projectId) 
				if(project){
					return project.project_name
				}
			}
		},
		getProjectID(projectName){
			if(this.allWorkerProjects){
				let project = this.allWorkerProjects.find(item => item.project_name==projectName) 
				if(project){
					return project.project
				}
			}
		},
		addItem(){
			this.mutableLoginLogoutPairs.push(
				{ project: this.project,
				login: {
					"id": null,
					"hour": null,
					"type": 0,
					"project_id": this.project, 
					"registered_at_utc": this.date.toISOString().substr(0, 10),
					"change": true
				},
				logout: {
					"id": null,
					"hour": null,
					"type": 1,
					"project_id": this.project, 
					"registered_at_utc": this.date.toISOString().substr(0, 10),
					"change": true
				}
				})
			this.$forceUpdate();
		},
		addRecord(pair, type){
			if(type==0){
				pair.login={
					"id": null,
					"hour": null,
					"type": 0,
					"project_id": this.project, 
					"registered_at_utc": this.date.toISOString().substr(0, 10),
					"change": true
				}
			}
			else if(type==1){
				pair.logout={
					"id": null,
					"hour": null,
					"type": 1,
					"project_id": this.project, 
					"registered_at_utc": this.date.toISOString().substr(0, 10),
					"change": true
				}
			}
			this.$forceUpdate();
			
		},
		deleteItem(item){
			item.deleted=true
			this.$forceUpdate();
		},
		restoreItem(item){
			item.deleted=false
			this.$forceUpdate();
		},
		deleteNewItem(pair, type){
			if(type==0) pair.login=""
			else if(type==1) pair.logout=""
			this.$forceUpdate()
		},
		getTodayOrTomorrow(registered_at_utc){
			if(registered_at_utc.substr(0, 10)==this.date.toISOString().substr(0, 10)){
				return this.dateToString(this.date)
			}
			return this.dateToString(this.getTomorrowDate())
		},
		getTomorrowDate(){
			let tomorrow = new Date(this.date)
			tomorrow.setDate(this.date.getDate() + 1)
			return tomorrow
		},
		dateToString(date){
			if(!date){
				return ''
			}
			let month = date.getMonth()+1
			if(month<10){
				month = "0"+month
			}
			let day = date.getDate()
			if(day<10){
				day = "0"+day
			}
			return day+"."+month
		},
		resetEditedData(){
			this.mutableLoginLogoutPairs=[]
			this.mutableUndefinedRecords=[]
			if(this.loginLogoutPairs){
				for(let item of this.loginLogoutPairs){
					let newItem = {
						project: item.project,
						login: item.login? Object.assign({}, item.login): "",
						logout: item.logout? Object.assign({}, item.logout): ""
					}
					this.mutableLoginLogoutPairs.push(newItem)
				}
			}
			if(this.undefinedRecords){
				for(let item of this.undefinedRecords){
					let newItem={
						id: item.id,
						hour: item.hour,
						type: item.type,
						deleted: false,
						project: item.project,
						date: item.registered_at_original
					}
					this.mutableUndefinedRecords.push(newItem)
				}
			}
			if(this.componentType==1)
			{
				this.mutableLoginLogoutPairs.push(
				{ 
					project: this.project,
				login: "",
				logout: ""
				})
			}
			this.selectedProject=""
		},
		save(){
			if(this.componentType==1 && !this.selectedProject){
				this.errorMessage=this.lviews.selectProject
				return
			}
			let recordsToSave= []
			for(let i=0;i<this.mutableLoginLogoutPairs.length;i++){
				let login = this.mutableLoginLogoutPairs[i].login
				let logout = this.mutableLoginLogoutPairs[i].logout
				if(login){
					if(!this.validateRecord(login)) return 
					recordsToSave.push({
						"id": login.id,
						"type": login.type,
						"project_id": this.selectedProject? this.getProjectID(this.selectedProject): this.project,
						"worker_id": this.worker_id,
						"registered_at": this.date.toISOString().substr(0, 10) +" "+login.hour,
						"deleted": login.deleted
					})
				}
				if(logout){
					if(!this.validateRecord(logout)) return 
					let dateToSave = new Date(this.date)
					if(login && login.hour>logout.hour){
						dateToSave.setDate(this.date.getDate() + 1)
					}
					recordsToSave.push({
						"id": logout.id,
						"type": logout.type,
						"project_id": this.selectedProject? this.getProjectID(this.selectedProject): this.project,
						"worker_id": this.worker_id,
						"registered_at": dateToSave.toISOString().substr(0, 10) +" "+logout.hour,
						"deleted": logout.deleted
					})
				}
			}
			if(this.mutableUndefinedRecords){
				for(let item of this.mutableUndefinedRecords){
					recordsToSave.push({
						"id": item.id,
						"type": item.type,
						"project_id": item.project,
						"worker_id": this.worker_id,
						"registered_at": item.date +" "+item.hour,
						"deleted": item.deleted
					})
				}
			}
			if(recordsToSave.length==0){
				this.errorMessage=this.lviews.recordRequired
				return
			}
			this.loading=true
			axios({
				method:'put',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/terminals/summary/cards/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				data: {
					"card_scans": recordsToSave
				}
			}).then(() => {
					this.loading=false
					this.errorMessage=""
					this.selectedProject=""
					this.dialog=false
					this.resetEditedData()
					this.refreshData()
			}).catch((error) => {
				this.loading=false
				if (typeof(error.response) === 'undefined'){
					this.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.errorMessage=this.lmessages.youDoNotHavePriviledgesToDoThatOperation
						return;
					case 429:
						this.errorMessage = this.lmessages.tooManyRequestsError;
						return;
					case 400:
						this.errorMessage = this.lmessages.requestRejected;
						return;
					case 500:
						this.errorMessage = this.lmessages.internalServerError;
						return;
					default:
						this.errorMessage = this.lmessages.errorOccured;
						return;
				}
			})
		},
		validateRecord(record){
			if(!record.hour){
				this.errorMessage=this.lviews.hourRequired
				return false
			}
			return true
		},
		confirmDelete(item){
			this.deleteItem(item)
		},
		refreshData() {
			this.$emit('refreshData')
		},
}
};
</script>

<style>
.actionButtonsWrapper{
	display: flex;
	justify-content: flex-end;
}

.v-btn{
	width: 80px;
}

.v-list-item{
	min-height: 20px !important;
}

.dateTextField{
	max-width: 70px;
}

.contentWrapper{
	max-height: 300px;
	overflow-y: scroll;
}

.addButtonWrapper{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px 20px 10px 0;
}

</style>
